import React from 'react'
import ParticlesBg from "particles-bg";

export default function Preloader() {
    return (
        <div className="preloader">
            <ParticlesBg color="#010101" type="cobweb"/>
            
            <div className="first-wrapper">

                <svg className="loader-svg" width="682" height="466" viewBox="0 0 682 466" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path id="vector1" d="M36.4 58.6C41.8511 103.081 44.2259 147.592 47.2444 192.289C48.7352 214.363 49.8983 236.48 50.4445 258.6C50.6873 268.433 53.9454 289 48.2222 298.244C44.2573 304.649 41.245 284.707 39.6 277.356C33.7151 251.057 29.6395 224.195 25.2 197.622C18.0102 154.587 9.33073 107.553 15.8667 63.8445C16.5483 59.2864 18.3711 45.1395 25.3778 44.5556C32.9504 43.9245 40.0437 60.3777 42.5333 64.9111C74.7825 123.633 96.3973 188.763 118.178 251.844C139.422 313.372 159.931 375.275 182.622 436.289C184.676 441.812 188.526 459.385 194.533 462.689C198.232 464.723 196.455 443.747 196.4 442.6" stroke="black" stroke-width="5" stroke-linecap="round" stroke-linejoin="round"/>
                    <path id="vector2" d="M31.6 287.4C62.7761 248.974 91.2507 210.778 114.622 167.044C120.439 156.159 124.997 144.737 130.622 133.8C134.918 125.447 137.119 155.664 137.289 157C143.455 205.56 150.145 254.044 156.311 302.6C157.505 312.001 157.7 322.938 160.844 332.022C162.401 336.519 160.324 322.504 159.6 317.8" stroke="black" stroke-width="5" stroke-linecap="round" stroke-linejoin="round"/>
                    <path id="vector3" d="M66.8 205.8C92.3047 179.702 120.823 159.195 150.8 138.6C163.607 129.801 176.187 119.887 189.822 112.378C193.68 110.253 200.9 107.074 198.178 114.6C189.977 137.274 174.437 157.636 162.8 178.6C155.399 191.934 147.412 204.785 139.333 217.711C138.474 219.086 129.69 230.602 130.978 233.178C132.064 235.351 135.467 231.141 137.289 229.533C142.456 224.974 146.689 220.887 153.556 218.956C175.496 212.785 193.528 231.047 206.711 246.156C221.586 263.203 235.218 287.096 255.244 298.778C275.336 310.498 282.385 277.051 286 265" stroke="black" stroke-width="5" stroke-linecap="round" stroke-linejoin="round"/>
                    <path id="vector4" d="M274.8 132.2C270.1 127.246 259.143 111.538 255.689 125.356C251.706 141.289 252.4 158.753 252.4 175.044C252.4 196.029 260.527 257.921 254 237.978C243.718 206.56 249.571 70.8979 303.778 131.844C318.916 148.864 331.17 167.818 342.8 187.311C350.07 199.497 358.497 217.719 371.689 224.822C382.366 230.571 381.641 212.032 381.556 206.956C381.074 178.278 371.912 149.54 360.489 123.489C328.679 50.9436 256.451 -0.592193 175.244 9.71111C104.792 18.65 41.6336 55.3277 2.79999 114.6" stroke="black" stroke-width="5" stroke-linecap="round" stroke-linejoin="round"/>
                    <path id="vector5" d="M399.6 21.8C401.139 58.9225 400.613 96.063 402.089 133.178C402.668 147.731 404.544 162.513 404.133 177.089C404.127 177.304 403.134 192.235 399.689 186.422C396.867 181.661 396.49 173.988 395.689 168.822C392.748 149.869 390.499 130.817 388.4 111.756C385.335 83.9171 374.919 46.7089 383.6 19.1333C389.498 0.397409 406.035 35.3669 408.4 40.0222C429.463 81.4792 442.174 126.812 459.867 169.711C470.809 196.243 484.601 222.525 501.289 245.978C515.076 265.354 511.721 219.764 511.6 215.4" stroke="black" stroke-width="5" stroke-linecap="round" stroke-linejoin="round"/>
                    <path id="vector6" d="M396.4 161C423.987 125.749 447.037 90.0599 458.444 46.3334C461.332 35.2649 464.005 23.9511 465.822 12.6445C465.853 12.4505 466.863 -0.104426 468.4 3.31114C471.582 10.3826 472.046 19.477 473.556 26.9556C477.861 48.2894 482.215 69.6875 487.6 90.7778C491.158 104.713 495.332 118.503 499.422 132.289C501.953 140.82 502.565 150.459 505.2 138.6" stroke="black" stroke-width="5" stroke-linecap="round" stroke-linejoin="round"/>
                    <path id="vector7" d="M439.6 31.4C468.355 18.6723 502.699 4.04487 534.711 3.04448C555.462 2.39599 510.074 35.6543 506.267 38.6889C495.041 47.6365 484.096 56.8709 473.378 66.4222C473.099 66.6706 456.401 80.79 464.489 79.6667C478.433 77.73 492.61 69.246 505.556 64.2889C531.825 54.2299 558.701 45.5646 585.733 37.8C607.139 31.6517 628.828 25.8619 650.978 23.1333C654.309 22.723 684.113 18.3469 677.645 30.8667C669.412 46.8012 644.569 59.0481 630.533 68.2C585.753 97.4002 538.403 122.652 491.689 148.556C355.155 224.264 -52.1967 455.278 85.6445 381.978C269.056 284.445 454.927 192.978 650.089 121C658.208 118.006 682.536 109.727 675.156 114.244C645.14 132.616 610.354 145.09 578.444 159.578C448.54 218.557 321.752 284.967 199.067 357.8C155.903 383.425 26.3025 459.957 70.7112 436.556C152.11 393.663 230.305 345.292 312.844 304.289C411.189 255.434 511.697 210.489 614 170.6C633.021 163.183 651.595 154.394 670.978 147.933C676.093 146.228 669.364 149.318 668.4 149.8" stroke="black" stroke-width="5" stroke-linecap="round" stroke-linejoin="round"/>

                <defs>
                    <style dangerouslySetInnerHTML={{ __html: `
                    
                    #vector1 {
                        stroke-dasharray: 981;
                        stroke-dashoffset: 981;
                        animation: sign 4s ease;
                        animation-fill-mode: forwards;
                    }
                    #vector2 {
                        stroke-dasharray: 405;
                        stroke-dashoffset: 405;
                        animation: sign 4s ease;
                        animation-fill-mode: forwards;
                    }
                    #vector3 {
                        stroke-dasharray: 522;
                        stroke-dashoffset: 522;
                        animation: sign 4s ease;
                        animation-fill-mode: forwards;
                    }
                    #vector4 {
                        stroke-dasharray: 982;
                        stroke-dashoffset: 982;
                        animation: sign 4s ease;
                        animation-fill-mode: forwards;
                    }
                    #vector5 {
                        stroke-dasharray: 652;
                        stroke-dashoffset: 652;
                        animation: sign 4s ease;
                        animation-fill-mode: forwards;
                    }
                    #vector6 {
                        stroke-dasharray: 329;
                        stroke-dashoffset: 329;
                        animation: sign 4s ease;
                        animation-fill-mode: forwards;
                    }
                    #vector7 {
                        stroke-dasharray: 3231;
                        stroke-dashoffset: 3231;
                        animation: sign 4s ease;
                        animation-fill-mode: forwards;
                    }

                    @keyframes sign {
                        to{
                            stroke-dashoffset: 0;
                        }
                    }
                    `, }} />
                    </defs>
                </svg>
            </div>
        </div>
    )
}
